import Vue from 'vue'

const state={
initKdIndex:-1
};
const mutations = {
    keyDownChoose:(state,lg)=>{
       state.initKdIndex++;
       if(state.initKdIndex > lg){
             state.initKdIndex = -1
       }
    },
    keyUpChoose:(state,lg)=>{
         state.initKdIndex--;
         if(state.initKdIndex < -1){
               state.initKdIndex = lg
         }
    },
  mouseClearStyle:(state)=>{
      state.initKdIndex=-1
  }

};
const actions = {
  keyDownChoose:({commit},lg) => {
    commit('keyDownChoose',lg)
  },
  keyUpChoose:({ commit },lg) =>{
    commit('keyUpChoose',lg)
  },
  mouseClearStyle:({ commit })=>{
     commit('mouseClearStyle')
  }

};
const getters = {
 keyDownNow:(state)=>{
     return state.initKdIndex
  }
};
export default {
  state,
  mutations,
  actions,
  getters
}
