import Vue from "vue";
import vuex from "vuex";

import search from "./modules/search";
import sendCode from "./modules/sendCode";
import keydown from "./modules/keydown";
import isLogin from "./modules/isLogin";
import product_info from "./product_info";

// 引入login
import login from './modules/login'

const state = {
  showNav: true, //控制菜单的显示和隐藏
  headLogo: true,
  routerGet: "",
  customerRole: "",
  showFooter: true,
  startYr: "",
  kw: "",
  industry_list_data: "",
  powerUser: sessionStorage.getItem("powerUser"),
  backSeachPath: sessionStorage.getItem("backSeachPath"),
  searchTitle: sessionStorage.getItem("searchTitle") || "上市企业分析",
  listingParame: sessionStorage.getItem("listingParame")
    ? JSON.parse(sessionStorage.getItem("listingParame"))
    : { selectedYear: 0, report_time: "", report_type: "" },
  inside: location.href.indexOf("172.16.10.117") + 1,
  years: "", //(2020三季报)
  DataEntry: {},
  CapitalCalculation: {},
  product_info: product_info,
  pageTitle: "数行科技",
}
Vue.use(vuex);
export default new vuex.Store({
  modules: {
    search,
    sendCode,
    keydown,
    isLogin,
    login,
  },
  state,
  mutations: {
    setCapitalCalculation(state, data) {
      state.CapitalCalculation = data;
    },
    setDataEntry(state, data) {
      state.DataEntry = data;
    },
    setShowNav: (state, data) => {
      state.showNav = data;
    },
    setPowerUser(state, data) {
      state.powerUser = data;
      sessionStorage.powerUser = data;
    },
    setBackSearchPath(state, path) {
      state.backSeachPath = path;
      sessionStorage.backSeachPath = path;
    },
    setSearchTitle(state, path) {
      state.searchTitle = path;
      sessionStorage.searchTitle = path;
    },
    setListingParame(state, setVaueObj) {
      state.listingParame[setVaueObj.name] = setVaueObj.value;
      sessionStorage.listingParame = JSON.stringify(state.listingParame);
    },
    setYears(state, val) {
      state.years = val;
    },
    setPageTitle(state, val) {
      state.pageTitle = val
    }
  },
});
