import Vue from "vue";
import Router from "vue-router";
import store from "@/store";

const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

const Sign = (r) =>
  require.ensure([], () => r(require("@/components/login/Sign")), "Sign");
const Login = (r) =>
  require.ensure([], () => r(require("@/components/login/Login")), "Login");
const Register = (r) =>
  require.ensure(
    [],
    () => r(require("@/pages/register/index.vue")),
    "Register"
  );
const Vimg = (r) =>
  require.ensure(
    [],
    () => r(require("@/pages/img.vue")),
    "Vimg"
  );
const reglink = (r) =>
  require.ensure(
    [],
    () => r(require("@/pages/register/reglink.vue")),
    "reglink"
  );
const BackPassWord = (r) =>
  require.ensure(
    [],
    () => r(require("@/components/login/BackPassWord")),
    "BackPassWord"
  );

const Search = (r) =>
  require.ensure(
    [],
    () => r(require("@/components/search/SearchEnterprise")),
    "Search"
  );
const SearchType = (r) =>
  require.ensure(
    [],
    () => r(require("@/components/search/SearchType")),
    "SearchType"
  );
const ListDebt = (r) =>
  require.ensure(
    [],
    () => r(require("@/components/search/ListDebt")),
    "ListDebt"
  );

const Custom = (r) =>
  require.ensure(
    [],
    () => r(require("@/components/search/CustomEnterprise")),
    "Custom"
  );

const CauseSearch = (r) =>
  require.ensure(
    [],
    () => r(require("@/components/search/CauseSearch")),
    "CauseSearch"
  );

const SearchResult = (r) =>
  require.ensure(
    [],
    () => r(require("@/components/search/SearchResult")),
    "SearchResult"
  );
const AllSearch = (r) =>
  require.ensure(
    [],
    () => r(require("@/components/search/AllSearchRoute")),
    "AllSearch"
  );
const DebtSearchResult = (r) =>
  require.ensure(
    [],
    () => r(require("@/components/search/DebtSearchResult")),
    "DebtSearchResult"
  );
const CustomResult = (r) =>
  require.ensure(
    [],
    () => r(require("@/components/search/CustomResult")),
    "CustomResult"
  );

const CauseResult = (r) =>
  require.ensure(
    [],
    () => r(require("@/components/search/CauseResult")),
    "CauseResult"
  );

const CompanyDetail = (r) =>
  require.ensure(
    [],
    () => r(require("@/components/CompanyDetail/CompanyDetail")),
    "CompanyDetail"
  );
const FinancialReport = (r) =>
  require.ensure(
    [],
    () => r(require("@/components/CompanyTab/FinancialReport ")),
    "FinancialReport"
  );
const ns = (r) =>
  require.ensure([], () => r(require("@/components/CompanyTab/ns")), "ns");
const Whitewash = (r) =>
  require.ensure(
    [],
    () => r(require("@/components/CompanyTab/Whitewash/index")),
    "Whitewash"
  );

const RiskWarning = (r) =>
  require.ensure(
    [],
    () => r(require("@/components/CompanyTab/RiskWarning/index")),
    "RiskWarning"
  );

const cashFlow = (r) =>
  require.ensure(
    [],
    () => r(require("@/components/CompanyTab/cash_flow/index")),
    "cashFlow"
  );
const StandardComponents = (r) =>
  require.ensure(
    [],
    () => r(require("@/components/CompanyTab/StandardComponents/index")),
    "StandardComponents"
  );



const CapitalCalculation = (r) =>
  require.ensure(
    [],
    () => r(require("@/pages/CapitalCalculation/index")),
    "CapitalCalculation"
  );

const DataEntry = (r) =>
  require.ensure(
    [],
    () => r(require("@/pages/CapitalCalculation/DataEntry")),
    "DataEntry"
  );
const CalculationResults = (r) =>
  require.ensure(
    [],
    () => r(require("@/pages/CapitalCalculation/CalculationResults")),
    "DataEntry"
  );
const CalculationHigh = (r) =>
  require.ensure(
    [],
    () => r(require("@/pages/CapitalCalculation/CalculationHigh")),
    "CalculationHigh"
  );

const RuleSystem = (r) =>
  require.ensure(
    [],
    () => r(require("@/components/CompanyTab/ruleSystem/index")),
    "RuleSystem"
  );
const RuleEdit = (r) =>
  require.ensure(
    [],
    () => r(require("@/components/CompanyTab/ruleSystem/ruleEdit")),
    "RuleEdit"
  );
const FinancialAnalysis = (r) =>
  require.ensure(
    [],
    () => r(require("@/components/CompanyTab/FinancialAnalysis/index")),
    "FinancialAnalysis"
  );



//个人中心
const CenterIndex = (r) =>
  require.ensure(
    [],
    () => r(require("@/components/PersonalCenter/Center")),
    "CenterIndex"
  );
const BasicInfro = (r) =>
  require.ensure(
    [],
    () => r(require("@/components/PersonalCenter/BasicInfor")),
    "BasicInfro"
  );
const BuyService = (r) =>
  require.ensure(
    [],
    () => r(require("@/components/PersonalCenter/BuyService")),
    "BuyService"
  );
const FindRecord = (r) =>
  require.ensure(
    [],
    () => r(require("@/components/PersonalCenter/FindRecord")),
    "FindRecord"
  );
const OrderManage = (r) =>
  require.ensure(
    [],
    () => r(require("@/components/PersonalCenter/OrderManage")),
    "OrderManage"
  );
// const ruleSystem = (r) =>
//   require.ensure(
//     [],
//     () => r(require("@/components/PersonalCenter/ruleSystem")),
//     "ruleSystem"
//   );
// const ruleEdit = (r) =>
//   require.ensure(
//     [],
//     () => r(require("@/components/PersonalCenter/ruleEdit")),
//     "ruleEdit"
//   );
const chekcSystemRule = (r) =>
  require.ensure(
    [],
    () => r(require("@/components/PersonalCenter/chekcSystemRule")),
    "chekcSystemRule"
  );
const checkLog = (r) =>
  require.ensure(
    [],
    () => r(require("@/components/PersonalCenter/checklog")),
    "checklog"
  );
const DataManager = (r) =>
  require.ensure(
    [],
    () => r(require("@/pages/DataManager/index")),
    "DataManager"
  );
const upReport = (r) =>
  require.ensure(
    [],
    () => r(require("@/pages/DataManager/upReport")),
    "upReport"
  );
const intelligenceUpReoprt = (r) =>
  require.ensure(
    [],
    () => r(require("@/pages/DataManager/intelligenceUpReoprt")),
    "intelligenceUpReoprt"
  );
/*支付详情页*/
const ClassDetail = (r) =>
  require.ensure(
    [],
    () => r(require("@/components/PersonalCenter/payDetail/Class")),
    "ClassDetail"
  );
const FirstClass = (r) =>
  require.ensure(
    [],
    () => r(require("@/components/PersonalCenter/payDetail/FirstClass")),
    "FirstClass"
  );
const SecondClass = (r) =>
  require.ensure(
    [],
    () => r(require("@/components/PersonalCenter/payDetail/SecondClass")),
    "SecondClass"
  );
const ThirdClass = (r) =>
  require.ensure(
    [],
    () => r(require("@/components/PersonalCenter/payDetail/ThirdClass")),
    "ThirdClass"
  );
const FourthClass = (r) =>
  require.ensure(
    [],
    () => r(require("@/components/PersonalCenter/payDetail/FourthClass")),
    "FourthClass"
  );

/* 主页导航 */
const Menu = (r) =>
  require.ensure([], () => r(require("@/components/menu/menu")), "menu");

/* 测试 */
const excl = (r) =>
  require.ensure([], () => r(require("@/components/demo/excl")), "excl");

/* 下载word */

const downWord = (r) =>
  require.ensure(
    [],
    () => r(require("@/components/downWord/downWord")),
    "downWord"
  );

const agreement = (r) =>
  require.ensure([], () => r(require("@/pages/agreement/index")), "agreement");
const database = (r) =>
  require.ensure([], () => r(require("@/pages/database/index")), "database");
const industry = (r) =>
  require.ensure([], () => r(require("@/pages/database/industry")), "industry");
const evaluate = (r) =>
  require.ensure([], () => r(require("@/pages/database/evaluate")), "evaluate");

const OCR = (r) =>
  require.ensure([], () => r(require("@/pages/reportManagement/ocrindex")), "OCR");

const MacroData = (r) =>
  require.ensure([], () => r(require("@/pages/database/MacroData/index")), "MacroData");

const invest_consumption = (r) =>
  require.ensure([], () => r(require("@/pages/database/MacroData/invest_consumption/index")), "invest_consumption");

const finance = (r) =>
  require.ensure([], () => r(require("@/pages/database/MacroData/finance/index")), "finance");

const NationalEconomy = (r) =>
  require.ensure([], () => r(require("@/pages/database/MacroData/NationalEconomy/index")), "NationalEconomy");



// 报表管理
const reportManagement = (r) =>
  require.ensure([], () => r(require("@/pages/reportManagement/index")), "reportManagement");

// 报表管理-附表
const pragmatic = (r) =>
  require.ensure([], () => r(require("@/pages/pragmatic/index")), "pragmatic");
// 手工录入
const handMadeFrom = (r) =>
  require.ensure([], () => r(require("@/pages/handMadeFrom/index")), "handMadeFrom");


// 手工录入资产负债
const balanceSheet = (r) =>
  require.ensure([], () => r(require("@/pages/handMadeFrom/balanceSheet")), "balanceSheet");
/* wxpay */

const wxpay = (r) =>
  require.ensure([], () => r(require("@/components/wxpay/wxpay")), "wxpay");

const nav = (r) =>
  require.ensure([], () => r(require("@/pages/nav/index")), "nav");

const mains = (r) =>
  require.ensure([], () => r(require("@/pages/main/index.vue")), "mains");
const router = new Router({
  routes: [
    {
      path: "/mains",
      name: "mains",
      component: mains,
      children: [
        {
          path: "/",
          name: "main",
          component: Search,
          redirect: "/Sign",
          children: [
            {
              path: "/SearchType",
              name: "SearchType",
              component: SearchType,
              redirect: "/ListDebt",
              children: [
                { path: "/ListDebt", name: "ListDebt", component: ListDebt },
                { path: "/Custom", name: "Custom", component: Custom },

                {
                  path: "/CauseSearch",
                  name: "CauseSearch",
                  component: CauseSearch,
                },
              ],
            },
          ],
        },

        {
          path: "/pragmatic",
          name: "pragmatic",
          component: pragmatic,
        },

        {
          path: "/handMadeFrom",
          name: "handMadeFrom",
          component: handMadeFrom,
        },
        {
          path: "/balanceSheet",
          name: "balanceSheet",
          component: balanceSheet,
        },
        {
          path: "/menu",
          name: "Menu",
          component: Menu,
        },
        {
          path: "/downWord",
          name: "downWord",
          component: downWord,
        },
        {
          path: "/database",
          name: "Database",
          component: database,
          children: [
            { path: "evaluate", name: "evaluate", component: evaluate },
            { path: "industry", name: "industry", component: industry },
            {
              path: "data-manager",
              name: "DataManager",
              component: DataManager,
            },
            {
              path: "data-manager/upReport",
              name: "upReport",
              component: upReport,
            },
            {
              path: "data-manager/intelligenceUpReoprt",
              name: "intelligenceUpReoprt",
              component: intelligenceUpReoprt,
            },
            {
              path: "RuleSystem",
              name: "RuleSystem",
              component: RuleSystem,
            },
            {
              path: "RuleEdit",
              name: "RuleEdit",
              component: RuleEdit,
            },
            {
              path: "OCR",
              name: "OCR",
              component: OCR,
            },
            {
              path: "MacroData",
              name: "MacroData",
              component: MacroData,
              redirect: "MacroData/NationalEconomy",
              children: [
                {
                  path: "NationalEconomy",
                  name: "NationalEconomy",
                  component: NationalEconomy,
                },
                {
                  path: "invest_consumption",
                  name: "invest_consumption",
                  component: invest_consumption,
                },
                {
                  path: "finance",
                  name: "finance",
                  component: finance,
                },
              ]
            }

          ],
        },

        {
          path: "/excl",
          name: "excl",
          component: excl,
        },
        /**/

        /*
    登录、注册、找回密码
    */

        /*搜索结果列表页*/
        {
          path: "/SearchResult",
          name: "SearchResult",
          component: SearchResult,
          redirect: "/SearchResult/AllSearch",
          children: [
            {
              path: "/SearchResult/AllSearch",
              name: "AllSearch",
              component: AllSearch,
              redirect: "/DebtSearchResult",
              children: [
                {
                  path: "/DebtSearchResult",
                  name: "DebtSearchResult",
                  component: DebtSearchResult,
                },
                {
                  path: "/CustomResult",
                  name: "CustomResult",
                  component: CustomResult,
                },

                {
                  path: "/CauseResult",
                  name: "CauseResult",
                  component: CauseResult,
                },
              ],
            },
          ],
        },

        {
          path: "/CompanyDetail/",
          name: "CompanyDetail",
          component: CompanyDetail,
          redirect: "/CompanyDetail/FinancialReport",
          children: [
            {
              path: "FinancialReport",
              name: "FinancialReport",
              component: FinancialReport,
              meta: { menu_id: "1" },
            },
            {
              path: "Whitewash",
              name: "Whitewash",
              component: Whitewash,
              meta: { menu_id: "2" },
            },
            {
              path: "FinancialAnalysis",
              name: "FinancialAnalysis",
              component: FinancialAnalysis,
              meta: { menu_id: "3" },
            },
            {
              path: "StandardComponents",
              name: "StandardComponents",
              component: StandardComponents,
              meta: { menu_id: "4" },
            },
            {
              path: "CapitalCalculation",
              name: "CapitalCalculation",
              component: CapitalCalculation,
              meta: { menu_id: "5" },
              children: [
                {
                  path: "DataEntry",
                  name: "DataEntry",
                  component: DataEntry,
                  meta: { menu_id: "5" },
                },
                {
                  path: "CalculationResults",
                  name: "CalculationResults",
                  component: CalculationResults,
                  meta: { menu_id: "5" },
                },
                {
                  path: "CalculationHigh",
                  name: "CalculationHigh",
                  component: CalculationHigh,
                  meta: { menu_id: "5" },
                },
              ],
            },
            {
              path: "cashFlow",
              name: "cashFlow",
              component: cashFlow,
              meta: { menu_id: "6" },
            },
            // 7为下载报告
            {
              path: "RiskWarning",
              name: "RiskWarning",
              component: RiskWarning,
              meta: { menu_id: "8" },
            },
            {
              path: "ns",
              name: "ns",
              component: ns,
            },
          ],
        },
        //个人中心
        {
          path: "/CenterIndex",
          name: "CenterIndex",
          component: CenterIndex,
          redirect: "/CenterIndex/BasicInfro",
          children: [
            {
              path: "BasicInfro",
              name: "BasicInfro",
              component: BasicInfro,
            },
            {
              path: "FindRecord",
              name: "FindRecord",
              component: FindRecord,
            },
            {
              path: "OrderManage",
              name: "OrderManage",
              component: OrderManage,
            },
            // {
            //   path: "ruleSystem",
            //   name: "ruleSystem",
            //   component: ruleSystem,
            // },
            // {
            //   path: "ruleEdit",
            //   name: "ruleEdit",
            //   component: ruleEdit,
            // },
            {
              path: "checkLog",
              name: "checkLog",
              component: checkLog,
            },

            {
              path: "chekcSystemRule",
              name: "chekcSystemRule",
              component: chekcSystemRule,
            },
            {
              path: "data-manager",
              name: "DataManager",
              component: DataManager,
            },
            {
              path: "data-manager/upReport",
              name: "upReport",
              component: upReport,
            },

          ],
        },
        /*购买点数*/
        {
          path: "/BuyService",
          name: "BuyService",
          component: BuyService,
        },
        /*点击进入购买详情页*/
        {
          path: "/ClassDetail",
          name: "ClassDetail",
          component: ClassDetail,
          redirect: "/ClassDetail/FirstClass",
          children: [
            {
              path: "FirstClass/:price/:point",
              name: "FirstClass",
              component: FirstClass,
            },
            {
              path: "SecondClass/:price/:point",
              name: "SecondClass",
              component: SecondClass,
            },
            {
              path: "ThirdClass/:price/:point",
              name: "ThirdClass",
              component: ThirdClass,
            },
            {
              path: "FourthClass/:price/:point",
              name: "FourthClass",
              component: FourthClass,
            },
          ],
        },
      ],
    },
    {
      path: "/nav",
      name: "nav",
      component: nav,
    },
    {
      path: "/wxpay",
      name: "wxpay",
      component: wxpay,
    },

    {
      path: "/Sign",
      name: "Sign",
      component: Sign,
      redirect: "/Login" /*配置默认路径*/,
      children: [
        {
          path: "/Login",
          name: "Login",
          component: Login,
        },

        /*
        找回密码
        */
        {
          path: "/backPassWord",
          name: "backPassWord",
          component: BackPassWord,
        },
      ],
    },
    // 新添加报表管理页面
    {
      path: "/reportManagement",
      name: "reportManagement",
      component: reportManagement,
    },

    {
      path: "/register",
      name: "Register",
      component: Register,
    },
    {
      path: "/img",
      name: "Vimg",
      component: Vimg,
    },
    {
      path: "/reglink",
      name: "reglink",
      component: reglink,
    },
    {
      path: "/agreement",
      name: "agreement",
      component: agreement,
    },
  ],
});

//
Vue.use(Router);
router.beforeEach((to, from, next) => {
  if (
    to.path == "/Login" ||
    to.path == "/backPassWord" ||
    to.path == "/agreement" ||
    to.path == "/register" ||
    to.path == "/reglink" ||
    to.path == "/reportManagement"
  ) {
    next();
  } else if (!sessionStorage.token) next("/Login");
  else next();
});
router.afterEach((to, from) => {
  if (
    to.path == "/Login" ||
    to.path == "/backPassWord" ||
    to.path == "/agreement" ||
    to.path == "/register" ||
    to.path == "/nav" ||
    to.path == "/reglink" ||
    to.path == "/reportManagement"
  ) {
    store.commit('setPageTitle', '数行科技');
    document.title = store.state.pageTitle;
  } else {
    store.commit('setPageTitle', '智能财报分析系统');
    document.title = store.state.pageTitle;
  }
})
export default router;
