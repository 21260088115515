const key = process.env.VUE_APP_NAME;

const zyzh = {
  name: "知因智慧",
  show: false,
  easy_name: "",
  menu_name: "知因智慧",
  logo: require("@/assets/img/logo/zyzh.png"),
  nav_logo: require("@/assets/img/logo/zyzh.png"),
  head_logo: require("@/assets/img/logo/zyzh.png"),
  nickname: "财务反欺诈系统",
  menu_logo: require("@/components/menu/img/menu.png"),
  register_system_name: "财务反欺诈系统",
};
const lianlian = {
  name: "连连科技",
  show: false,
  easy_name: "",
  menu_name: "连连科技",
  logo: require("@/assets/img/logo/logo_lianlian.png"),
  nav_logo: require("@/assets/img/logo/logo_lianlian.png"),
  head_logo: require("@/assets/img/logo/logo_lianlian.png"),
  nickname: "财务反欺诈系统",
  menu_logo: require("@/components/menu/img/menu.png"),
  register_system_name: "财务反欺诈系统",
};
const mo_ren = {
  name: "数行科技", //报告里的数行科技水印，改为连连科技；报告包含：对标分析报告、通用财务分析报告、信贷图文版报告、信贷表格版报告；
  show: true, //1、注册、登陆页面的用户协议一行隐藏；
  easy_name: "数行", //智能识别报表的结果页面的“数行标准科目”，改为“识别后标准科目”；
  logo: require("@/assets/img/logo/small_logo.png"), //4、登陆、忘记密码、产品导航、登陆到数信淘客/流水系统后的页面logo，改为连连科技的logo；
  nav_logo: require("@/assets/img/logo/small_logo.png"), //媒体
  head_logo: require("@/assets/img/logo/small_logo.png"),
  nickname: "智能财报分析", //产品导航页的数行家 · 财判改为智能财报分析系统；
  menu_logo: require("@/components/menu/img/img_logo@2x.png"),
  register_system_name: "智能财报分析",
};

var exports = {
  lianlian: lianlian,
  mo_ren: mo_ren,
  zyzh: zyzh,
};

export default exports[key];
