<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import common from "./assets/css/public/public.css";

export default {
  data() {
    return {
      hideClassPageHeadArr: ["/ListDebt", "/Custom", "/menu", "/agreement"],
    };
  },

  watch: {
    $route: function(to, from) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },

  methods: {
    // 记录点击时间
    // updateLastTime() {
    //   this.$store.commit('login/SET_LASTTIME'
    //     , new Date().getTime());
    //   // console.log(this.$store.state.login.lastTime)
    // },
    searchShowFn() {
      window.listDebt.searchShowFn(1);
    },
  },
  created() {
    // 在页面加载时读取sessionStorage
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("store"))
        )
      );
    }
    // 在页面刷新时将store保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });

    document.title = this.$store.state.pageTitle;
  },
  mounted() {
    window.hideSelectTree = [];
    $(document).bind("click", function(e) {
      var target = $(e.target);
      if (target.closest(".select_tree_box").length == 0) {
        window.hideSelectTree.map((item) => {
          item && item.hide && item.hide();
        });
      }
    });
  },
};
</script>

<style>
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
/*
避免iview样式影响
*/

.container {
  min-width: 1200px !important;
}

.topnav {
  min-width: 1200px !important;
}

.el-range-separator {
  width: 27px !important;
}

body {
  font-size: 1rem !important;
  /* position: relative; */
}

.v-page-li-active {
  border-color: #3a85fd !important;
  background-color: #3a85fd !important;
}

.v-page-li-active a {
  color: #fff !important;
}

#app {
  width: 100%;
  height: 100%;
  position: relative;
}

.footers {
  position: absolute;
  bottom: 2.5rem;
}

.search-icon {
  width: 31px;
  height: 31px;
  background: url("./assets/img/head/3.0sxtk_search_icon_homepage.png")
    no-repeat center center;
}

.user-common-icon {
  width: 28px;
  height: 28px;
  background: url("./assets/img/head/3.0sxtk_search_icon_user.png") no-repeat
    center center;
}

.icon-vip {
  width: 20px;
  height: 20px;
  background: url("./assets/img/head/3.0sxtk_search_img_king.png") no-repeat
    center center;
}
</style>
