<template>
  <div>
    <div id="dialog" v-if="show">
      <div class="dialog position-fixed w-100" @click.stop="close">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content" style="border: none">
            <div class="dialog_bgc">
              <div
                style="
                  width: 100%;
                  height: 130px;
                  background: rgba(238, 238, 238, 1);
                  text-align: center;
                "
              >
                <img
                  style="height: 100%; width: 100%"
                  :src="text == '点数不足,需充值!' ? pointerImg : pointerImg2"
                  alt=""
                  srcset=""
                />
              </div>
            </div>
            <div style="height: 200px">
              <div style="width: 80%; margin: 0 auto">
                <h2 style="margin-top: 20px; margin-top: 20px; text-align: center">
                  {{ text || "去充值" }}
                </h2>

                <div
                  v-if="text == '点数不足,需充值!'"
                  style="text-align: center; margin-top: 30px"
                >
                  <el-button type="primary">去充值</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      show: false,
      pointerImg: require("./img/pointer.png"),
      pointerImg2: require("./img/bj.png"),
    };
  },
  methods: {
    showDialog() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
  },
  created() {},
};
</script>
<style>
.dialog {
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 999999;
}
.dialog_bgc {
  background: url("../../assets/img/dialog/dialog_bgc.png") no-repeat top center;
}
.modal-header {
  border-bottom: 0;
}
.dialog_pic {
  width: 4.5rem;
  height: 3.1rem;
}
.dialog_close {
  width: 1.6rem;
  height: 1.6rem;
  background: url("../../assets/img/dialog/dialog_close_prev.png") no-repeat center center;
}
.dialog_close:hover {
  background: url("../../assets/img/dialog/dialog_close_hover.png") no-repeat center
    center;
}
.dialog_btn_sign {
  height: 2.3rem;
  color: #4a81fd;
  border-radius: 25px;
  background-color: transparent;
  border: 1px solid #4a81fd;
}
.dialog_btn_sign:hover {
  color: #fff;
  background-color: #4a81fd;
}
.link_vip_tip:hover {
  color: #4a81fd;
  border-bottom: 1px solid #4a81fd !important;
}
.tip-text {
  color: #000;
  max-height: 90%;
  max-height: 30rem;
  overflow-y: auto;
  word-break: break-all;
}
</style>
