
const state={
  code:60,
  timer:'',
  startText:'',
  errorTip:''
};
const mutations = {
   countCode(state){
     state.timer= setInterval(function(){
             state.code--;
       if(state.code<=0){
           clearInterval(state.timer);
            state.code=60;
       }
        },1000);

   },
  clearCountCode(state){
      clearInterval(state.timer);
      state.code=60;
  }


};
const actions = {
    countCode({ commit}){
        commit('countCode');
    },
  clearCountCode({ commit}){
      commit('clearCountCode');
  }



};
const getters = {
    count(state){
      return state.code
    },
  startText(state){
      if(state.code==60){
       return state.startText='发送验证码'
      }else if(state.code<60){
      return  state.startText=state.code+'秒后再次发送'
      }
  },
  errorTip(state){
      return state.errorTip
  }


};
export default {
  state,
  mutations,
  actions,
  getters
}
