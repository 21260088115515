// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

import Vue from "vue";
import App from "./App";

import axios from "axios";
import router from "./router";
import vuex from "vuex";

import store from "./store";
import element from "./element/index";
import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';
// 加载动画
import GlobalComponents from "./components/index";
import uploadLoading from "./components/uploadloading";
import "./assets/css/bootstrap.css";
import TipComponents from "./components/tips";

// 引入样式
import "vue-easytable/libs/themes-base/index.css";
// 导入 table 和 分页组件
import { VPagination } from "vue-easytable";
import * as echarts from "echarts";


/*日期插件*/
import moment from "moment"

// window.BASE_URL = BASE_URL

// 172.16.10.117:10020
window.BASE_URL = process.env.NODE_ENV == "development" ? "172.16.10.117:10020" : "";
window.BASE_URL = sessionStorage.BASE_URL || window.BASE_URL;

import "view-design/dist/styles/iview.css";
import {
  Checkbox,
  Upload,
  Select,
  Option,
  OptionGroup,
  Input,
  Message,
  Modal,
  Button,
  RadioGroup,
  Radio,
  Switch,
  Tooltip,
} from "view-design";
import SeeksRelationGraph from "relation-graph";

Vue.component("i-checkbox", Checkbox);
Vue.component("Tooltip", Tooltip);
Vue.component("i-upload", Upload);
Vue.component("i-select", Select);
Vue.component("i-option", Option);
Vue.component("i-optiongroup", OptionGroup);
Vue.component("i-input", Input);

Vue.component("i-modal", Modal);
Vue.component("i-button", Button);
Vue.component("RadioGroup", RadioGroup);
Vue.component("Radio", Radio);
Vue.component("i-switch", Switch);
Vue.component("SeeksRelationGraph", SeeksRelationGraph);

Vue.prototype.$moment = moment
moment.locale('zh-cn')
Vue.prototype.$axios = axios;
Vue.prototype.$http = axios;
Vue.prototype.$Message = Message;
Vue.prototype.$Modal = Modal;
Vue.prototype.$VM = {
  name: "VM",
  dec: "组件之间通信",
};

Vue.use(GlobalComponents);
Vue.use(uploadLoading);
Vue.use(TipComponents);
Vue.use(vuex);
Vue.config.productionTip = false;

// 分页表格注册到全局
Vue.component(VPagination.name, VPagination);

//⾦额千分符全局挂载会在整数后添加两个0---------
export function stateFormat(row, column, cellValue) {
  // console.log(row, column, cellValue);
  let value = cellValue / 10000
  // console.log(value);
  if (value) {
    return Number(value)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+\.)/g, ($0, $1) => {
        return $1 + ",";
      })
      .replace(/\.$/, "");
  }
}
Vue.prototype.stateFormat = stateFormat

// 权限相关
Vue.prototype.getUserRole = function (value) {

  const user_permission = JSON.parse(sessionStorage.getItem('user_permission'))
  const val = user_permission.findIndex(fruit => fruit === value);
  if (val == '-1') {
    return false;
  } else {
    return true;
  }
}

import './assets/css/driver/driver.css'

Vue.use(element);
Vue.use(ElementUI);
function name() { }
const console = window.console;
for (const key in console) {
  if (console.hasOwnProperty(key)) {
    if (key == "log") continue;
    window.console[key] = name;
  }
}

Vue.prototype.$echarts = echarts;
Vue.use(echarts);
/* eslint-disable no-new */
// 总是复现
window.confirm = function () { };
window.prompt = function () { };
new Vue({
  el: "#app",
  router,
  store,
  components: { App },
  template: "<App/>",
}).$mount(Vue);
