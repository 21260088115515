
import LoadingCm from './loading/uploading.vue'
const uploadLoading = {};
uploadLoading.install = (Vue) => {
    const LoadConstructor = Vue.extend(LoadingCm);
    const dialogInstance = new LoadConstructor();
    dialogInstance.$mount(document.createElement('div'));
    document.body.appendChild(dialogInstance.$el);
    Vue.prototype.$uploadLoadingShow = () => {
        dialogInstance.loading = 'true';
    };
    Vue.prototype.$uploadLoadingHide = () => {
        dialogInstance.loading = 'false';
    }
};
export default uploadLoading
