import Vue from 'vue'

const state={
 checkoutSearchData:''
};
const mutations = {
    queryPutData(){
    //  console.log('开始查询')
    },
    getCookie (name) {
        var value = '; ' + document.cookie
        var parts = value.split('; ' + name + '=')

        if (parts.length === 2)
          return parts.pop().split(';').shift()
      },

};
const actions = {
    queryPutData({ commit }){
      commit('queryPutData')
    },

  getCookie({ commit },name){
      commit('getCookie',name)
    },
};

const getters = {

};
export default {
  state,
  mutations,
  actions,
  getters
}
