<template>
    <div id="loading" v-show="loading=='true'">
      <div class="loading position-fixed w-100 row justify-content-center align-items-center">
        <img src="../../assets/img/loading/loading.gif" alt="" style="width: 10%;">
      <span class="fz-26">{{msg}}</span>
      </div>
    </div>
</template>
<script>
  //
    export default {
      data(){
        return{
          loading:'false',
          msg: '加载中.......'
        }
      },
      watch: {
        "loading": function () {
          var substrHref =  window.location.href.substr(window.location.href.indexOf('#')+1)
          if(substrHref == '/CompanyDetail/FinancialReport' || substrHref == '/CompanyDetail/Whitewash' || substrHref == '/CompanyDetail/FinancialAnalysis') this.msg = "后台分析中"
          else this.msg = "加载中......."
        }
      },

    }
</script>


<style>
.loading{
    top: 0;
    left: 0;
    height:100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 1000;
}



</style>
