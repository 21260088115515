// 导入自己需要的组件
import {
  Table,
  TableColumn,
  Button,
  Tooltip,
  Input,
  Select,
  DatePicker,
  Tag,
  Option,
  Link,
  Form,
  Dialog,
  FormItem,
  Tabs,
  TabPane,
  Message,
  MessageBox,
  Autocomplete,
  Popover,
  Collapse,
  CollapseItem,
  OptionGroup,
  Checkbox,
  CheckboxGroup,
  Radio,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Cascader,
} from "element-ui";

import "@/assets/css/element-ui/theme/index.css";

const element = {
  install: function(Vue) {
    Vue.prototype.$ELEMENT = { size: "small" };
    Vue.prototype.$message = Message;
    Vue.prototype.$confirm = MessageBox.confirm;
    Vue.use(Dropdown);
    Vue.use(Cascader);

    Vue.use(DropdownMenu);
    Vue.use(DropdownItem);
    Vue.use(Table);
    Vue.use(Tabs);
    Vue.use(TabPane);
    Vue.use(FormItem);
    Vue.use(TableColumn);
    Vue.use(Tooltip);
    Vue.use(Input);
    Vue.use(Dialog);
    Vue.use(Form);
    Vue.use(Link);
    Vue.use(Tag);
    Vue.use(Button);
    Vue.use(Select);
    Vue.use(Option);
    Vue.use(OptionGroup);

    Vue.use(Autocomplete);
    Vue.use(Popover);
    Vue.use(Collapse);
    Vue.use(CollapseItem);
    Vue.use(CheckboxGroup);
    Vue.use(Checkbox);
    Vue.use(Radio);

    Vue.use(DatePicker);
  },
};
export default element;
