<template>
  <div id="loading" v-show="loading == 'true'">
    <div class="loading position-fixed w-100 text-center">
      <el-progress
        type="circle"
        :percentage="total"
        style="margin-top: 20%"
      ></el-progress>
      <div style="color: #fff; font-size: 16px">{{ msg }}</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: "false",
      msg: "识别中，请稍后！",
      total: 0,
    };
  },
  created() {
    setTimeout(() => {
      this.total = 20;
    }, 1000);
    setTimeout(() => {
      this.total = 50;
    }, 1500);
    setTimeout(() => {
      this.total = 80;
    }, 2000);
    setTimeout(() => {
      this.total = 100;
    }, 2500);
    // this.start(100, "root", 4);
  },
  methods: {
    // roll(total, idname, step) {
    //   let n = 0;
    //   //   return function () {
    //   //     n = n + step >= total ? total : n + step;
    //   //     if (n <= total) {
    //   //       document.getElementById(idname).innerHTML = n;
    //   //     }
    //   //   };
    //   n = n + step >= total ? total : n + step;
    //   if (n <= total) {
    //     this.total = n;
    //     // document.getElementById(idname).innerHTML = n;
    //   }
    //   return this.total;
    // },
    // start(index, idname, step, runtime = 1000) {
    //   let rolling = roll(index, idname, step);
    //   runtime = runtime >= 300 ? runtime : 1000;
    //   for (let i = 0; i < index / step; i++) {
    //     let timer = setTimeout(rolling, (runtime / index) * i * step);
    //   }
    //   clearTimeout(timer);
    // },
  },
  watch: {
    // loading: function () {
    //   var substrHref = window.location.href.substr(
    //     window.location.href.indexOf("#") + 1
    //   );
    //   if (
    //     substrHref == "/CompanyDetail/FinancialReport" ||
    //     substrHref == "/CompanyDetail/Whitewash" ||
    //     substrHref == "/CompanyDetail/FinancialAnalysis"
    //   )
    //     this.msg = "后台分析中";
    //   else this.msg = "识别中，请稍后！";
    // },
  },
};
</script>


<style lang="less" scoped>
.loading {
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
/deep/.el-progress-circle {
  width: 100px !important;
  height: 100px !important;
}
/deep/.el-progress__text {
  color: #fff !important;
}
</style>
