import tip from './Tip/Tip'

const TipComponents = {};
TipComponents.install = (Vue, options)=>{
  const LoadConstructor = Vue.extend(tip);
  const dialogInstance  = new LoadConstructor();
  dialogInstance.$mount(document.createElement('div'));
  document.body.appendChild(dialogInstance.$el);
  Vue.prototype.$tip= (option) => {
    dialogInstance.text =option.text
    dialogInstance.show = option.text && option.text !== ''
  };
};
export default TipComponents
