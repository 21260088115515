import * as sessionStorage from "./../localStorage";
const state = {
  loginStore: sessionStorage.getLocalData("userData") || {},
  hasLogin: sessionStorage.getLocalData("userData") ? true : false,
};
const mutations = {
  addLoginUser(state, userData) {
    // console.log(state, userData);
    sessionStorage.setLocalData("userData", userData);
    sessionStorage.getLocalData("userData");
    state.loginStore = sessionStorage.getLocalData("userData");
    state.hasLogin = true;
  },
  setHasLogin: (state, data) => {
    state.hasLogin = data;
    if (!data) sessionStorage.clearLocalData();
  },
};
const actions = {
  addLoginUser({ commit }, userData) {
    commit("addLoginUser", userData);
  },
};

const getters = {};
export default {
  state,
  mutations,
  actions,
  getters,
};
