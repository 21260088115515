
  import LoadingCm from './loading/loading'
    const GlobalComponents = {};
    GlobalComponents.install = (Vue)=>{
      const LoadConstructor = Vue.extend(LoadingCm);
      const dialogInstance  = new LoadConstructor();
     dialogInstance.$mount(document.createElement('div'));
      document.body.appendChild(dialogInstance.$el);
       Vue.prototype.$loadingShow= () => {
        dialogInstance.loading ='true';
      };
       Vue.prototype.$loadingHide= () => {
        dialogInstance.loading ='false';
      }
  };
export default GlobalComponents
