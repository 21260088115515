
export  const setLocalData = (obj, dataObj) =>{
 return window.sessionStorage.setItem(obj,JSON.stringify(dataObj))
};
export const getLocalData =(obj)=>{
  return JSON.parse(window.sessionStorage.getItem(obj))
};


export const clearLocalData =()=>{
     sessionStorage.clear();
};

export const removeLocalData =(obj)=>{
      sessionStorage.removeItem(obj);
};
